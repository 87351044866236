
import { getDataUrlMimeType } from "@/util/mimeUtils";
import { EChartsOption, init } from "echarts";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import {
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent,
    VisualMapComponent,
} from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer, SVGRenderer } from "echarts/renderers";
import {
    addListener as addResizeDetectorListener,
    removeListener as removeResizeDetectorListener,
    ResizeCallback as ResizeDetectorCallback,
} from "resize-detector";
import Vue from "vue";
import EChart from "vue-echarts";

use([
    BarChart,
    CanvasRenderer,
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    PieChart,
    SVGRenderer,
    TitleComponent,
    TooltipComponent,
    VisualMapComponent,
]);

const SVG_DATA_URL_HTML_ENTITIES_REPLACE = [
    { search: "&lt;", replace: "&#60;" },
    { search: "&gt;", replace: "&#62;" },
    { search: "&amp;", replace: "&#38;" },
    { search: "&nbsp;", replace: "&#160;" },
].map((entity) => ({
    search: encodeURIComponent(entity.search),
    replace: encodeURIComponent(entity.replace),
}));

function fixSvgSyntax(uriEncodedDataUrl: string): string {
    return SVG_DATA_URL_HTML_ENTITIES_REPLACE.reduce(
        (dataUrl, entity) => dataUrl.replace(new RegExp(entity.search, "g"), entity.replace),
        uriEncodedDataUrl
    );
}

export default Vue.extend({
    props: {
        fullChartHeightFactor: {
            type: Number,
            default: 1,
        },
        fullChartWidthFactor: {
            type: Number,
            default: 1,
        },
        height: {
            type: Number,
            required: true,
        },
        hasSeries: {
            type: Boolean,
            required: true,
        },
        option: {
            type: Object as () => EChartsOption,
            required: true,
        },
    },

    data() {
        return {
            resizeDetectorCallback: null as ResizeDetectorCallback | null,
            resizeTimeout: null as number | null,
            width: 0,
        };
    },

    methods: {
        getChartAsPngDataUrl(): string | null {
            const width = Math.ceil(this.width * this.fullChartWidthFactor);
            const height = Math.ceil(this.height * this.fullChartHeightFactor);

            if (!width || !height) {
                return null;
            }

            const iframe = this.$refs.iframe as HTMLIFrameElement;
            iframe.style.display = "block";
            const win = iframe.contentWindow!;
            const body = win.document.body;
            body.innerHTML = "";
            const container = document.createElement("div");
            body.appendChild(container);

            const echarts = init(container, undefined, { height, width, renderer: "canvas" });

            echarts.setOption({ ...this.option, dataZoom: undefined });

            const dataUrl = echarts.getConnectedDataURL({
                type: "png",
                pixelRatio: 2,
                backgroundColor: "#FFFFFF",
            });

            echarts.dispose();

            body.innerHTML = "";
            iframe.style.display = "none";

            if (dataUrl.length < 10 || getDataUrlMimeType(dataUrl)?.subtype !== "png") {
                return null;
            }

            return dataUrl;
        },

        getChartAsSvgDataUrl(): string | null {
            const width = Math.ceil(this.width * this.fullChartWidthFactor);
            const height = Math.ceil(this.height * this.fullChartHeightFactor);

            if (!width || !height) {
                return null;
            }

            const iframe = this.$refs.iframe as HTMLIFrameElement;
            iframe.style.display = "block";
            const win = iframe.contentWindow!;
            const body = win.document.body;
            body.innerHTML = "";
            const container = document.createElement("div");
            body.appendChild(container);

            const echarts = init(container, undefined, { height, width, renderer: "svg" });

            echarts.setOption({ ...this.option, dataZoom: undefined });

            const dataUrl = echarts.getConnectedDataURL({
                type: "svg",
                backgroundColor: "#FFFFFF",
            });

            echarts.dispose();

            body.innerHTML = "";
            iframe.style.display = "none";

            if (dataUrl.length < 10 || getDataUrlMimeType(dataUrl)?.subtype !== "svg+xml") {
                return null;
            }

            return fixSvgSyntax(dataUrl);
        },

        resize() {
            if (!this.$refs.wrapper) {
                return;
            }

            this.width = (this.$refs.wrapper as HTMLDivElement).clientWidth;

            this.$emit("resize", this.width);
        },
    },

    mounted() {
        this.resize();

        this.resizeDetectorCallback = () => {
            clearTimeout(this.resizeTimeout ?? undefined);

            this.resizeTimeout = setTimeout(() => {
                this.resize();
            }, 250);
        };

        addResizeDetectorListener(this.$refs.wrapper as HTMLDivElement, this.resizeDetectorCallback);
    },

    beforeDestroy() {
        if (this.resizeDetectorCallback) {
            removeResizeDetectorListener(this.$refs.wrapper as HTMLDivElement, this.resizeDetectorCallback);
        }
    },

    components: {
        EChart,
    },
});
